import { CartItem, OrderItem } from "../models/order"

/**
 * 刺繍設定された商品がカートに入っているか
 */
export const isEmbroideryProductsInCart = (cartItems: (CartItem | OrderItem)[]) => {
  return cartItems.some((cartItem) => {
    return cartItem.embroidery && cartItem.embroidery.text !== ""
  })
}

/**
 * 刺繍限定商品がカートに入っているか
 */
export const embroideryExclusiveProductsInCart = (cartItems: (CartItem | OrderItem)[]) => {
  return cartItems.some((cartItem) => {
    return cartItem.embroidery_exclusive
  })
}
